import React from 'react';
import "../../assets/css/App.css"
import '../../assets/css/index.css';
import { Button, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../../components/SEO';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import PlantRoomExamples from '../../components/PlantRoomExamples';
import ContactForm from "../../components/ContactForm";


const StyledPhoneLink = styled.a`
  color: #007bff;
`;

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Remeha Boiler Fault Codes: When to Call an Engineer"
        description="Find out what that fault code on your Remeha boiler means and see whether you can fix it yourself or need to call a qualified heating engineer from BBIS Heating"
        keywords=""
      />

      <>
      <div className='grey-segment'>
        <Col lg>

          <div class="text-left">
            <h1 class="heading-color pb-4">Remeha Boiler Fault Codes: When to Call in an Engineer</h1>
            <Img
            fluid={data.gasboilerheatinginstallationsgens.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="Image of Remeha Boilers with Fault Codes"
          />
            <p className='pt-4'>If your Remeha boiler is showing a fault code, you’re probably wondering what it means. While some faults are easy to fix yourself, others might require more skilled knowledge from an engineer.</p>
            <p>It’s important to <strong>never try to fix a fault with your boiler if you’re not confident,</strong> or if it involves working with the wiring or gas supply. Leave these faults to a <strong>qualified engineer</strong> who can safely clear your Remeha boiler fault code for you.</p>
            <p>But to help you decide whether to try to fix a Remeha boiler fault code yourself or call a boiler company, we’ve compiled the most <strong>common Remeha Avanta boiler fault codes</strong> and their possible causes to help you troubleshoot.</p>
          </div>

          <div>
            <h2 className="heading-color">E0: Possible return sensor fault</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>If your boiler is showing an E0 fault code, it usually means that your return or boiler <strong>temperature sensor is faulty, not connected properly,</strong> or there has been a <strong>short circuit.</strong> </p>
            <p>If you know what you’re looking for, you can <strong>check the wiring and connections</strong> to the sensors to see if everything is in the right place. You could also <strong>measure the resistance</strong> in the wiring and connection with a multimeter if you have one.</p>
            <p>If everything looks ok there, the next step is to <strong>call an engineer.</strong> They can take the sensors out and measure the resistance with a multimeter.</p>
          </div>

          <div>
            <h2 class="heading-color"id="E1">E1/E2: Flow/return temperature too high</h2>
            <p><strong>Call an engineer?: Maybe</strong></p>
            <p>An E1 fault code means that your Remeha boiler’s <strong>flow temperature is higher than the set maximum temperature.</strong> This could be due to <strong>insufficient water in the system, no through flow</strong> or <strong>too much air in the system.</strong></p>
            <p>The first thing to do is check the <strong>minimum water pressure</strong> on the gauge. If it’s too low, you may need to <strong>repressurise your boiler,</strong> which you can do yourself if you’re confident.</p>
            <p>If the through flow is the problem, you can <strong>check whether the pump is working.</strong> Turn the spindle with a screwdriver and check the wiring. If turning the spindle works and the wiring looks ok, the <strong>pump is faulty</strong> and you’ll need to <strong>call an engineer</strong> to replace it.</p>
            <p>If <strong>too much air is in the system</strong>, you can <strong>vent your boiler.</strong> Some Remeha boilers have an automatic venting programme, but if you’re unsure it’s best to <strong>call an engineer.</strong></p>
            <p>A similar fault code is <strong>E2</strong>, which means that the <strong>return temperature is higher than the flow temperature.</strong> You can follow the same steps as E1 to troubleshoot this fault code.</p>
          </div>
          <div>
            <h2 class="heading-color">E3: Possible control unit or earth fault</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>If you see an E3 fault code, this could be a dangerous one. Check that <strong>the boiler’s power supply from the switch is properly earthed -</strong> if you don’t know what to look for, you may want to call <strong>an electrician.</strong></p>
            <p>If you or an electrician find that it is correctly earthed, then it means the <storng>control unit is faulty,</storng> and you’ll need a <strong>gas engineer to replace it.</strong></p>
            
            <h2 class="heading-color">E4: More than 5 attempts to start without a flame forming</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>If you see the E4 fault code on your Remeha boiler, it’s generally due to 1 of 3 causes: <strong>no ignition spark, a spark but no flame,</strong> or there is a <strong>flame but there’s insufficient ionisation.</strong></p>
            <p><strong>This is definitely one to leave to an engineer to troubleshoot.</strong> They can check the connections between the ignition wire and other wiring, check that the gas tap, pressure and pipes are all working properly, and check that there is no recirculation of flue gases.</p>
            
            <h2 class="heading-color">E6: Unwanted flame formation</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>Unfortunately, if you see fault code E6, it’s usually because your <strong>control unit is faulty.</strong> You’ll need to <strong>call in a boiler company</strong> to replace it.</p>
            
            <h2 class="heading-color">E7: No water in the boiler</h2>
            <p><strong>Call an engineer?: Maybe</strong></p>
            <p>An E7 Remeha boiler code means that there’s <strong>no water in your boiler</strong> or the <strong>pump isn’t working.</strong> Similar to codes E1 and E2, you can check the pressure and repressurise your boiler if you need to.</p>
            <p>You can also check to see if the <strong>pump is working,</strong> and if not you’ll need an engineer to replace it for you. You may also need to <strong>vent your system,</strong> which is best to ask an engineer to do if you’re not sure how to carry it out.</p>
            
            <h2 class="heading-color">E8: Fan fault</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>You’ll see the E8 fault code if your boiler’s <strong>fan isn’t working</strong> at all or if it <strong>won’t stop running.</strong></p>
            <p>It’s best to call an engineer to check the <strong>fan’s wiring</strong> and check for <strong>excess draughts</strong> around the chimney. The chances are they’ll need to <strong>replace the fan.</strong></p>
            
            <h2 class="heading-color">E10: No through flow during venting</h2>
            <p><strong>Call an engineer?: Maybe</strong></p>
            <p>The E10 fault code shows up during a venting cycle if there’s no through flow. There are 3 reasons why this might happen: <strong>insufficient water, too much air in the system</strong> or a <strong>faulty pump.</strong></p>
            <p>The first thing to do is to <strong>check the pressure</strong> and <strong>look for leaks.</strong> If you don’t find any leaks but the pressure is low, you can try <strong>repressurising the boiler.</strong></p>
            <p>Next, <strong>check that the pump is working</strong> by turning the spindle with a screwdriver and checking the wiring as we’ve described in <strong><a href="#E1">E1</a></strong>. If the pump doesn’t respond but the wiring looks fine, then you’ll need an engineer to replace the pump.</p>
            <p>You or an engineer can also <strong>vent the system</strong> to see if this solves the problem.</p>
            
            <h2 class="heading-color">E11: Air box temperature too high</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>If you see the E11 Remeha boiler fault code, it means there’s <strong>air leaking from the heat exchanger</strong> to the air box. <strong>Don’t attempt to fix this yourself</strong> - call an engineer who can check the viewing glass and heat exchanger front plate to check that they’re sealed and tightened.</p>
            
            <h2 class="heading-color">E13: Heat exchanger fuse protection initiated</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>The E13 Remeha boiler fault code means that there’s a <strong>fault with the heat exchanger</strong> that has <strong>caused the fuse protection to respond.</strong></p>
            <p><strong>An engineer needs to replace the heat exchanger</strong> once they’ve found and solved the initial cause, which could be due to insufficient water, no through flow or simply a faulty heat exchanger.</p>
            
            <h2 class="heading-color">E43/E44: Automation settings</h2>
            <p><strong>Call an engineer?: Maybe</strong></p>
            <p>Fault codes E43 and E44 are one of those <strong>‘turn it off and on again’ problems.</strong> Hopefully, you or someone you know still has your boiler manual - in there, it will tell you how to <strong>reset the boiler.</strong></p>
            <p>If you still get the fault codes after resetting, it’s best to call an engineer.</p>
            
            <h2 class="heading-color">E45: Boiler control unit</h2>
            <p><strong>Call an engineer?: Yes</strong></p>
            <p>Unfortunately, if you see fault code E45, you’ll need to <strong>replace the boiler control unit.</strong> Call an engineer to help you do this.</p>
            
            <h2 class="heading-color">What are boiler fault codes?</h2>
            <p><strong>Boiler fault codes are a boiler’s way of telling you there’s a problem.</strong> Some fault codes have a direct solution, whereas others may direct you to where a problem lies but you need to find the cause.</p>
            <p><strong>Some boiler fault codes can be solved by yourself,</strong> but many relate to a wider problem that needs to be fixed by a professional. It’s always best to have a fault code investigated by a <strong>Gas Safe-registered boiler engineer</strong> if you’re unsure how to fix a problem yourself.</p>
            
            <h2 class="heading-color">Should I turn my boiler off if it has a fault?</h2>
            <p>If you can’t figure out what the fault code relates to, then <strong>it’s best to turn your boiler off if it has a fault and call an engineer.</strong> Make sure you <strong>take a note of the fault code</strong> before you switch the boiler off so you can tell the engineer what you saw.</p>
            <p>If the code is telling you something like there’s no water in the boiler, you can go ahead and try to repressurise the system to see if this solves it. But if it doesn’t, it’s best to turn it off and call in a boiler company.</p>
            
            <p>BBIS Heating are experts in Remeha boilers and can help find and fix problems that are flagged by fault codes. Fill in our form to get in touch with us about repairing your Remeha boiler in London, Hertfordshire and surrounding areas.</p>
            <h3 className='heading-color'>Contact BBIS Heating</h3>
            <ContactForm></ContactForm>
          </div>
        </Col>
      </div>
      </>
    </Layout>
  );
};

export const query = graphql`
  query WhatIsAPlantRoomImagess {
    gasboilerheatinginstallationsgens: file(
      relativePath: { eq: "Projects/burlingtonclose-4.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1137) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
